import * as React from 'react';
import { useState, useContext, useCallback } from 'react';
import { OrderedSet } from 'immutable';
import styled, { css } from 'styled-components';

import DescriptionBox from 'views/components/aggregationbuilder/DescriptionBox';
import { defaultCompare } from 'logic/DefaultCompare';
import type { Widgets } from 'views/stores/WidgetStore';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import Select from 'components/common/Select';
import Button from 'components/bootstrap/Button';
import SortableList from 'components/common/SortableList';
import Input from 'components/bootstrap/Input';
import IconButton from 'components/common/IconButton';
import StickyBottomActions from 'views/components/widgets/StickyBottomActions';
import SaveOrCancelButtons from 'views/components/widgets/SaveOrCancelButtons';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const Col = styled.div`
  height: 100%;
`;

const ControlsCol = styled(Col)`
  min-width: 315px;
  max-width: 500px;
  flex: 1.2;
  padding-right: 5px;
  margin-right: 10px;
  overflow-y: auto;
`;

const LogViewCol = styled(Col)`
  flex: 3;
  overflow-y: hidden;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const SelectButtonGroup = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const FieldListItemContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const Warning = styled.div(({ theme }) => css`
  padding: 5px;
  border: 1px solid ${theme.colors.variant.warning};
  border-radius: 3px;
  background: ${theme.colors.variant.lightest.warning};
  color: ${theme.utils.contrastingColor(theme.colors.variant.lightest.warning)};
`);

type Props = {
  config: LogViewWidgetConfig,
  onChange: (newConfig: LogViewWidgetConfig) => Promise<Widgets>,
  children: React.ReactNode,
  onCancel: () => void,
  onSubmit: () => void,
};

const optionsMapper = ({ name }) => ({ value: name, label: name });
const itemsMapper = (name) => ({ id: name, title: name });

const LogViewEditWidget = ({ config, onChange, children, onCancel, onSubmit }: Props) => {
  const [currentConfig, setCurrentConfig] = useState(config);
  const [selectedFields, setSelectedFields] = useState<string | undefined | null>();
  const { all } = useContext(FieldTypesContext);

  const fieldOptions = all.map(optionsMapper)
    .filter((opt) => !currentConfig.fields.has(opt.label))
    .sort((opt1, opt2) => defaultCompare(opt1.label, opt2.label))
    .toArray();

  const setTieBreaker = useCallback((value) => {
    const tieBreaker = value && value.length > 0 ? value : null;
    const newConfig = currentConfig.toBuilder().tieBreaker(tieBreaker).build();
    setCurrentConfig(newConfig);
    onChange(newConfig);
  }, [currentConfig, onChange]);
  const updateFields = useCallback((fields) => {
    const newConfig = currentConfig.toBuilder().fields(OrderedSet(fields)).build();
    setCurrentConfig(newConfig);
    onChange(newConfig);
  }, [currentConfig, onChange]);
  const addFields = useCallback(() => {
    if (selectedFields) {
      updateFields(currentConfig.fields.concat(selectedFields.split(',')));
    }

    setSelectedFields(undefined);
  }, [currentConfig.fields, selectedFields, updateFields]);
  const removeField = useCallback((field) => {
    updateFields(currentConfig.fields.remove(field));
  }, [currentConfig.fields, updateFields]);
  const sortableItemContent = useCallback(({ item: { title } }) => {
    return (
      <FieldListItemContainer>
        {title}
        <IconButton title="Remove" name="times" onClick={() => removeField(title)} />
      </FieldListItemContainer>
    );
  }, [removeField]);
  const changeSortDirection = useCallback((direction) => {
    const newConfig = currentConfig.toBuilder().sort(direction).build();
    setCurrentConfig(newConfig);
    onChange(newConfig);
  }, [currentConfig, onChange]);

  return (
    <Container>
      <ControlsCol>
        <StickyBottomActions actions={<SaveOrCancelButtons onCancel={onCancel} onSubmit={onSubmit} />} alignActionsAtBottom>
          <DescriptionBox description="Sorting direction by Timestamp">
            <Input type="radio"
                   id="sort-asc"
                   name="sort"
                   value="asc"
                   label="Ascending"
                   onChange={() => changeSortDirection('ASC')}
                   checked={currentConfig.sort === 'ASC'} />
            <Input type="radio"
                   id="sort-desc"
                   name="sort"
                   value="desc"
                   label="Descending"
                   onChange={() => changeSortDirection('DESC')}
                   checked={currentConfig.sort === 'DESC'} />
            <Input id="tie-breaker"
                   name="tie-breaker"
                   help="This fields decides the sort order of two fields if the two fields have the same timestamp."
                   label="Tie breaker">
              <Select name="Tie Breaker"
                      options={fieldOptions}
                      value={currentConfig.tieBreaker}
                      onChange={setTieBreaker}
                      aria-label="Tie Breaker" />
            </Input>
          </DescriptionBox>
          <DescriptionBox description="Field Selection and Order">
            <form onSubmit={addFields}>
              <SelectButtonGroup>
                <StyledSelect name="Fields"
                              aria-label="Field Selection"
                              options={fieldOptions}
                              value={selectedFields}
                              multi
                              maxMenuHeight={200}
                              onChange={setSelectedFields} />
                <Button onClick={addFields}>Add</Button>
              </SelectButtonGroup>
            </form>
            <SortableList items={currentConfig.fields.map(itemsMapper).toArray()}
                          customContentRender={sortableItemContent}
                          onMoveItem={(values) => updateFields(values.map(({ title }) => title))} />
            {currentConfig.fields.size <= 0 && <Warning>No fields selected</Warning>}
          </DescriptionBox>
        </StickyBottomActions>
      </ControlsCol>
      <LogViewCol>
        {children}
      </LogViewCol>
    </Container>
  );
};

export default LogViewEditWidget;
