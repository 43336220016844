import { Map, Set } from 'immutable';

import Widget from 'views/logic/widgets/Widget';
import type { WidgetState } from 'views/logic/widgets/Widget';
import type { QueryString, TimeRange } from 'views/logic/queries/Query';
import isDeepEqual from 'stores/isDeepEqual';
import isEqualForSearch from 'views/stores/isEqualForSearch';
import type MessagesWidget from 'views/logic/widgets/MessagesWidget';
import { MESSAGE_FIELD } from 'views/Constants';
import type { FiltersType, SearchFilter } from 'views/types';

import LogViewWidgetConfig from './LogViewWidgetConfig';

export default class LogViewWidget extends Widget {
  constructor(
    id: string,
    config: LogViewWidgetConfig,
    filter: string | undefined | null,
    timerange: TimeRange | undefined | null,
    query: QueryString | undefined | null,
    streams: Array<string> = [],
    filters: FiltersType | Array<SearchFilter> = [],
  ) {
    super(id, LogViewWidget.type, config, filter, timerange, query, streams, filters);
  }

  static type = 'logs';

  static defaultTitle = 'Untitled Log View';

  // eslint-disable-next-line class-methods-use-this
  get isExportable() {
    return true;
  }

  static fromJSON(value: WidgetState) {
    const { id, config, filter, timerange, query, streams, filters } = value;

    return new LogViewWidget(id, LogViewWidgetConfig.fromJSON(config), filter, timerange, query, streams, filters);
  }

  static fromMessageTable(messageTable: MessagesWidget): LogViewWidget {
    const { showMessageRow, fields } = messageTable.config;
    const needsMessageField = showMessageRow && !fields.includes(MESSAGE_FIELD);

    const logViewFields = Set(needsMessageField ? [...fields, MESSAGE_FIELD] : fields);

    return LogViewWidget.builder()
      .id(messageTable.id)
      .config(
        LogViewWidgetConfig.createDefault()
          .toBuilder()
          .fields(logViewFields)
          .build(),
      ).build();
  }

  equals(other: any) {
    if (other instanceof LogViewWidget) {
      return ['id', 'config', 'filter', 'timerange', 'query', 'streams', 'filters'].every((key) => isDeepEqual(this._value[key], other[key]));
    }

    return false;
  }

  equalsForSearch(other: any) {
    if (other instanceof LogViewWidget) {
      return ['id', 'config', 'filter', 'timerange', 'query', 'streams', 'filters'].every((key) => isEqualForSearch(this._value[key], other[key]));
    }

    return false;
  }

  toBuilder() {
    const { id, config, filter, timerange, query, streams, filters } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Map({ id, config, filter, timerange, query, streams, filters }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }

  static isLogViewWidget(widget: Widget) {
    return widget && widget.type === LogViewWidget.type;
  }
}

class Builder extends Widget.Builder {
  build(): LogViewWidget {
    const { id, config, filter, timerange, query, streams, filters } = this.value.toObject();

    return new LogViewWidget(id, config, filter, timerange, query, streams, filters);
  }
}
